import { CreditCardBrand } from '@/types/payment';
import { PaymentOptionData } from '@/types/payment';

export const paymentOptions: PaymentOptionData[] = [
  {
    name: 'credit_debit_card',
    value: 'CREDIT_CARD',
    icon: [
      '/assets/icons/visa.svg',
      '/assets/icons/master-card.svg',
      '/assets/icons/mada.svg',
    ],
  },
  {
    name: 'get_quotation_and_pay_later',
    value: 'PAY_LATER',
    icon: '/assets/icons/quotation.svg',
  },
  {
    name: 'sadad_service',
    value: 'SADAD',
    icon: '/assets/icons/sadad.svg',
    disabled: true,
    comingSoon: true,
  },
];

export const creditCardBrands: CreditCardBrand[] = [
  {
    name: 'Visa',
    value: 'VISA',
    icon: '/assets/icons/visa.svg'
  },
  {
    name: 'Master card',
    value: 'MASTER_CARD',
    icon: '/assets/icons/master-card.svg'
  },
  {
    name: 'Mada',
    value: 'MADA',
    icon: '/assets/icons/mada.svg'
  },
]

export const PAYMENT_STATUSES = {
  APPROVED: 'APPROVED' as 'APPROVED',
  FAILED: 'FAILED' as 'FAILED',
};
