
import { Component, Vue } from 'vue-property-decorator';
import { PaymentData, PaymentOption, RecentBillingContact } from '@/types/payment';
import namespaces from '@/store/namespaces';
import { Action, Getter } from 'vuex-class';
import { UpdatePayment } from '@/store/modules/campaign-wizard/types';
import PayCreditCard from './components/PayCreditCard.vue';
import PayLater from './components/PayLater.vue';
import PaySadad from './components/PaySadad.vue';
import { publicRouteNames } from '@/route-names/public';
import SkeletonLoader from '@/ui-components/SkeletonLoader/SkeletonLoader.vue';
import { ResetCampaign } from '@/store/modules/campaign-wizard/types';
import { ShowSnackbar } from '@/types/snackbar';
import { Campaign } from '@/types/campaigns';
import { Nullable } from '@/types/misc';
import { loadRecentBillingContactAction } from '@/actions/company-profiles/actions';
import { loadCampaignLocations } from '@/actions/campaigns/actions';
import { LocationData } from '@/types/locations';

@Component({
  components: {
    SkeletonLoader,
    PayCreditCard,
    PayLater,
    PaySadad,
  },
})
export default class CampaignSubmit extends Vue {
  @Action('loadCampaign', { namespace: namespaces.CommonModule })
  public loadCampaign!: any;

  @Getter('payment', { namespace: namespaces.CampaignWizardModule })
  public campaignWizardPaymentData!: PaymentData;

  @Action('updatePayment', { namespace: namespaces.CampaignWizardModule })
  public updatePayment!: UpdatePayment;

  @Action('showSnackbar', { namespace: namespaces.UiModule })
  public showSnackbar!: ShowSnackbar;

  @Action('resetCampaign', { namespace: namespaces.CampaignWizardModule })
  public resetCampaign!: ResetCampaign;

  loading = false;
  billingInfo!: RecentBillingContact;
  campaignData: Nullable<Campaign> = null;

  public get campaignId() {
    return this.$route.params['id'];
  }

  public get billingInfoPhone() {
    return this.$route.params['billingInfoPhone'] || this.billingInfo.phone;
  }

  public get billingInfoEmail() {
    return this.$route.params['billingInfoEmail'] || this.billingInfo.email;
  }

  get paymentData() {
    return this.campaignWizardPaymentData;
  }

  set paymentData(payment: PaymentData) {
    this.updatePayment(payment);
  }

  get isPayCreditCard() {
    return this.paymentData.option === 'CREDIT_CARD';
  }

  get isPaySadad() {
    return this.paymentData.option === 'SADAD';
  }

  get isPayLater() {
    return this.paymentData.option === 'PAY_LATER';
  }

  async created() {
    // TODO: should be a route guard
    if (!this.campaignId) {
      this.goBackToPayment();
    }

    this.loading = true;

    try {
      this.campaignData = await this.loadCampaign(this.campaignId);

      const campaignPrice = this.campaignData?.PAYMENT_BILL!.TOTAL_PRICE;
      this.billingInfo = await loadRecentBillingContactAction()
      const paymentOptions: Record<string, PaymentOption> = {
        credit: 'CREDIT_CARD',
        later: 'PAY_LATER'
      };

      const option = paymentOptions[this.$route.params.method];
      this.paymentData = { totalPrice: campaignPrice, option: option };

      if (this.campaignData?.DESIGNER_EMAIL) {
        this.showSnackbar({
          text: this.$t('email_sent', {email: this.campaignData.DESIGNER_EMAIL}).toString(),
          color: 'info'
        })
      }
    } catch (err) {
      this.goBackToPayment();
      this.showSnackbar({
        color: 'danger',
        text: this.$t('error_loading_campaign').toString() + '::' + (err as Error).message,
      });
    }
    finally {
      this.loading = false;
    }
  }

  public goBackToPayment() {
    this.$router.push({ ...publicRouteNames.CREATE_CAMPAIGN });
  }
}
