
import { Component, Prop, Vue } from 'vue-property-decorator';
import { PaymentData } from '@/types/payment';
import { Getter } from 'vuex-class';
import { AuthorizedUser } from '@/types/users';
import namespaces from '@/store/namespaces';
import Divider from "@/ui-components/Divider/Divider.vue";
import { returnPolicyLink } from '@/statics/main';

@Component({
  components: {
    Divider
  },
})
export default class PayNowSummary extends Vue {
  @Prop({ required: true, type: Object }) public paymentData!: PaymentData;

  @Getter('userInfo', { namespace: namespaces.AuthModule })
  public userInfo!: AuthorizedUser;

  returnPolicyLink = returnPolicyLink;

  public get campaignPrice() {
    return this.paymentData.totalPrice;
  }
}
