
import { Component, Prop, Vue } from 'vue-property-decorator';
import PayLaterSummary from './PayLaterSummary.vue';
import { PaymentData } from '@/types/payment';
import { advertiserRouteNames } from '@/route-names/advertiser';
import { Campaign } from '@/types/campaigns';
import { SYSTEM_STATUS } from '@/statics/system-status';
import { Action } from 'vuex-class';
import namespaces from '@/store/namespaces';
import { ResetCampaign } from '@/store/modules/campaign-wizard/types';
import { publicRouteNames } from '@/route-names/public';
import { ShowSnackbar } from '@/types/snackbar';
import { AdSpacePriceControl } from '@/types/adspaces';

@Component({
  components: {
    PayLaterSummary
  }
})
export default class PayLater extends Vue {
  @Prop({ required: true, type: Object }) public paymentData!: PaymentData;
  @Prop({ required: true, type: Object }) public campaignData!: Campaign;
  @Prop() public billingInfoEmail!: string;
  @Prop() public billingInfoPhone!: string;

  @Action('resetCampaign', { namespace: namespaces.CampaignWizardModule })
  public resetCampaign!: ResetCampaign;

  @Action('showSnackbar', { namespace: namespaces.UiModule })
  public showSnackbar!: ShowSnackbar;

  isCampaignPendingPayment = this.campaignData.STATUS.VAL === SYSTEM_STATUS.PENDING_PAYMENT.VAL;

  get isAllSelectedLocationsPriceControlByShasha() {
    return this.campaignData.PRICE_CONTROL === AdSpacePriceControl.BY_SHASHA || !this.campaignData.PRICE_CONTROL
  }

  async created() {
    if (!this.isCampaignPendingPayment) {
      this.goBackToPayment();
      this.paymentErrorSnackbar();
    }
  }

  private paymentErrorSnackbar() {
    const color = 'danger';
    const text = this.$t('something_went_wrong') as string;
    this.showSnackbar({ text, color });
  }

  goToDashboard() {
    this.$router.push({ ...advertiserRouteNames.DASHBOARD })
  }

  public goBackToPayment() {
    this.$router.push({ ...publicRouteNames.CREATE_CAMPAIGN })
  }

  public beforeDestroy() {
    if (this.isCampaignPendingPayment) {
      this.resetCampaign();
    }
  }
}
