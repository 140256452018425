
import { Component, Prop, Vue } from 'vue-property-decorator';
import PayLaterSummary from './PayLaterSummary.vue';
import { PaymentData } from '@/types/payment';
import { advertiserRouteNames } from '@/route-names/advertiser';

@Component({
  components: {
    PayLaterSummary
  }
})
export default class PaySadad extends Vue {
  @Prop({ required: true, type: Object }) public paymentData!: PaymentData;

  public goToDashboard() {
    this.$router.push({ ...advertiserRouteNames.DASHBOARD })
  }
}
