
import { Component, Prop, Vue } from 'vue-property-decorator';
import PayNowSummary from './PayNowSummary.vue';
import { PaymentData } from '@/types/payment';
import { advertiserRouteNames } from '@/route-names/advertiser';
import { publicRouteNames } from '@/route-names/public';
import { Action } from 'vuex-class';
import namespaces from '@/store/namespaces';
import { ResetCampaign } from '@/store/modules/campaign-wizard/types';
import { ShowSnackbar } from '@/types/snackbar';
import { Campaign } from '@/types/campaigns';
import { PAYMENT_STATUSES } from '@/statics/payment';

@Component({
  components: { PayNowSummary },
})
export default class PayCreditCard extends Vue {
  @Prop({ required: true, type: Object }) public paymentData!: PaymentData;
  @Prop({ required: true, type: Object }) public campaignData!: Campaign;

  @Action('showSnackbar', { namespace: namespaces.UiModule })
  public showSnackbar!: ShowSnackbar;

  @Action('resetCampaign', { namespace: namespaces.CampaignWizardModule })
  public resetCampaign!: ResetCampaign;

  isPaymentSuccessful =
    this.campaignData.PAYMENT_STATUS === PAYMENT_STATUSES.APPROVED;

  public async created() {
    if (!this.isPaymentSuccessful) {
      this.goBackToPayment();
      this.paymentErrorSnackbar();
    }
  }

  private paymentErrorSnackbar() {
    const color = 'danger';
    const text = this.$t('payment_error_message') as string;
    this.showSnackbar({ text, color });
  }

  public goBackToPayment() {
    this.$router.push({ ...publicRouteNames.CREATE_CAMPAIGN });
  }

  public goToDashboard() {
    this.$router.push({ ...advertiserRouteNames.DASHBOARD });
  }
  public beforeDestroy() {
    if(this.isPaymentSuccessful){
      this.resetCampaign();
    }
  }
}
