
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { AuthorizedUser } from '@/types/users';
import namespaces from '@/store/namespaces';
import Divider from "@/ui-components/Divider/Divider.vue";
import { PaymentData } from '@/types/payment';
import { Campaign } from '@/types/campaigns';
import { FirebaseRemoteConfig } from '@/firebase/firebase-app';
import firebaseNames from '@/statics/firebase-names';
import moment from 'moment';
import { returnPolicyLink } from '@/statics/main';

@Component({
  components: {
    Divider
  },
})
export default class PayLaterSummary extends Vue {
  @Prop({ required: true, type: Object }) public paymentData!: PaymentData;
  @Prop({ required: true, type: Object }) public campaignData!: Campaign;
  @Prop() public billingInfoEmail!: string;
  @Prop() public billingInfoPhone!: string;
  @Getter('userInfo', { namespace: namespaces.AuthModule })
  public userInfo!: AuthorizedUser;

  public sadadCode = 12345
  public referenceNumber = 12345
  public reservedUntilDate: Date | undefined;
  returnPolicyLink = returnPolicyLink;

  public get campaignPrice() {
    return this.paymentData.totalPrice;
  }

  created() {
    const pendingPaymentTimeoutInHours = FirebaseRemoteConfig.getNumber(firebaseNames.remoteConfig.PENDING_PAYMENT_TIMEOUT_IN_HOURS);
    this.campaignPendingPaymentTimeout = moment().add(pendingPaymentTimeoutInHours, 'hours').toDate();
  }

  public get campaignPendingPaymentTimeout() {
    return this.reservedUntilDate as Date;
  }

  public set campaignPendingPaymentTimeout(date: Date) {
    this.reservedUntilDate = date;
  }
}
