
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "VerticalDivider",
})
export default class VerticalDivider extends Vue {
  @Prop({ type: String }) type!: string;
  @Prop({ type: String }) height!: string;
  @Prop({ type: String }) width!: string;
  @Prop({ type: String }) maxHeight!: string;
  @Prop({ type: String }) maxWidth!: string;
  @Prop({ type: Boolean, default: true }) loading!: boolean;
  @Prop({ type: Boolean, default: true }) tile!: boolean;
  @Prop({ type: Boolean, default: true }) boilerplate!: boolean;
}
